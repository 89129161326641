import React, { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import ReactTooltip from "react-tooltip";
import { Layout } from "../components/Layout";

function Home() {
    const [boards, setBoards] = useState([]);

    const clearLocalStorage = () => {
        Object.keys(localStorage).forEach((board) => {
            localStorage.removeItem(board);
        });
        window.location.reload();
    };

    useEffect(() => {
        const loadedBoards = [];
        Object.keys(localStorage).forEach((board) => {
            loadedBoards.push(localStorage.getItem(board));
        });
        setBoards(loadedBoards);
    }, []);

    return (
        <Layout>
            <span>
                <ReactTooltip />
                <div className="form-container">

                    {boards.length !== 0 ? (
                        <Alert variant="primary">
                            <center>
                                <div className="centered">
                                    <a href={`/whiteboard#id=${uuidv4()}`}>
                                        <Button
                                            className="login-buttons margin-left bottom-margin"
                                            variant="success"
                                        >
                                            <i className="fa fa-pencil"></i>&nbsp;Create Board
                                        </Button>
                                    </a>
                                    <Button
                                        variant="danger"
                                        className="login-buttons margin-left bottom-margin"
                                        onClick={clearLocalStorage}
                                    >
                                        <i className="fa fa-trash"></i>&nbsp;Delete All Boards
                                    </Button>
                                </div>
                                <div className="row margin">
                                    {boards
                                        .sort((a, b) => JSON.parse(a).date < JSON.parse(b).date)
                                        .sort((a, b) => JSON.parse(a).time < JSON.parse(b).time)
                                        .map((board) => {
                                            const jsonBoard = JSON.parse(board); { console.log(jsonBoard) }
                                            console.log(jsonBoard)
                                            return (
                                                <div className="col-md-6 col-xs-12">
                                                    <div className="thumbnail">
                                                        <a href={`/whiteboard#id=${jsonBoard.id}`}>
                                                            <img src={jsonBoard.img} />
                                                            <h1>{jsonBoard.name}</h1>
                                                        </a>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </center>
                        </Alert>
                    ) : (
                        <Alert variant="primary" className="centered">
                            <div className="centered bottom-margin">
                                <a href={`/whiteboard/#id=${uuidv4()}`}>
                                    <Button
                                        className="login-buttons margin-left bottom-margin"
                                        variant="success"
                                    >
                                        <i className="fa fa-pencil"></i>&nbsp;Create Board
                                    </Button>
                                </a>
                                <Button
                                    variant="danger"
                                    className="login-buttons margin-left bottom-margin"
                                    onClick={clearLocalStorage}
                                >
                                    <i className="fa fa-trash"></i>&nbsp;Delete All Boards
                                </Button>
                            </div>
                            You have 0 boards.  This page will be filled with your own creations using the whiteboard app.  Create a board and store keep it in local storage.
                            <div className="frowny centered">
                                <i className="fa fa-folder" />
                            </div>
                        </Alert>
                    )}
                </div>
            </span>
        </Layout>
    );
}

export default Home;
